import { Component, OnInit } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    public _ss:SharingService,
  ) {
   }

  ngOnInit(): void {
  }

  onSave(){
    this._ss.saveEvent();
  }

  onCreate(){
    this._ss.createEvent();
  }
}
