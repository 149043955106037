<div class="">
    <div class="centers" *ngIf="centers">
        <div class="target" [ngClass]="center.status ? 'isOpen' : 'isClosed'" *ngFor="let center of centers; let i = index">
            <div class="header">
                <h1><span>Centro:</span> {{center.name}}</h1>
                <agm-map [latitude]="center.address.latitude" [longitude]="center.address.longitude">
                    <agm-marker [latitude]="center.address.latitude" [longitude]="center.address.longitude" zoom="15"></agm-marker>
                </agm-map>
            </div>
            <div class="center-content">
                <div class="content-info">
                    <span><b>Calle:</b> {{center.address.street}}</span>
                    <span><b>Ciudad:</b> {{center.address.council}}</span>
                    <span><b>Provincia:</b> {{center.address.province}}</span>
                    <span><b>Número:</b> {{center.address.number}}</span>
                </div>
                <a class="btn-center" (click)="setCenter(center.id)">Modificar</a>
            </div>
        </div>
    </div>
    <app-modify-center [center]="center"></app-modify-center>
</div>