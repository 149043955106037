<div class="container-menu">
    <ul class="nav" [ngClass]="!_ss.isOpen ? 'closed' : ''">
        
        <li (click)="openMenu()">
            <a [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faBars"></fa-icon>
                </div>
                <span>Cerrar</span>
            </a>
        </li>
        
     
        <li *ngIf="_ss.getWorkShopSettings()">
            <a [routerLink]="['/configuracion', _ss.getWorkShopSettings().id_workshop]" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faCog"></fa-icon>
                </div>
                <span>Configuración</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/paginas']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faFile"></fa-icon>
                </div>
                <span>Páginas</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/promociones']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faDolar"></fa-icon>
                </div>
                <span>Campañas</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/servicios']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faWrench"></fa-icon>
                </div>
                <span>Servicios</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/colaboradores']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faCar"></fa-icon>
                </div>
                <span>Colaboradores</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/centros']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faChat"></fa-icon>
                </div>
                <span>Centros</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/vo']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <div class="vo">
                        <span class="social-icon" [inlineSVG]="vo"></span>
                    </div>
                </div>
                <span>VO</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/social']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <span class="social-icon" [inlineSVG]="facebook"></span>
                </div>
                <span>Redes Sociales</span>
            </a>
        </li>

        <li>
            <a [routerLink]="['/politicasycondiciones']" routerLinkActive="active" [ngClass]="_ss.isOpen ? 'open' : ''">
                <div class="icon">
                    <fa-icon [icon]="faBook"></fa-icon>
                </div>
                <span>Políticas y Condiciones</span>
            </a>
        </li>
    </ul>
</div>
