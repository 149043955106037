<div class="header">
    <h1>{{_ss.titleSection}}</h1>
    <div class="menu-options">
        <a *ngIf="_ss.saveButton" class="menu-btn" (click)="onSave()" #savebutton>
            <div class="icon">
                <img src="../../../../assets/icons/toolbar/toolbar-save-and-edit.svg">
            </div>
            Guardar
        </a>
        <a *ngIf="_ss.newButton" class="menu-btn" (click)="onCreate()" #newbutton>
            <div class="icon">
                <img src="../../../../assets/icons/toolbar/toolbar-add.svg">
            </div>
            Crear
        </a>
    </div>
</div>