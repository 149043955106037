import { Component, OnInit, ViewChild } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { FileUploader } from 'ng2-file-upload';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { AppconfigService } from 'src/app/services/appconfig.service';
import {Setting} from '../../models/settings';
import { Domain } from 'src/app/models/domain';
import { faEye, faPencilAlt } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.scss']
})
export class ConfigurationComponent implements OnInit {

  @ViewChild('selectedPicture') selectedPicture: any;
  
  saveSubscription;
  newSubscription;

  imagePath;
  message;
  imgURL;

  isuploadingProgress;
  uploadStatusProgress;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;
  filename;
  uploader:FileUploader;
  saveWithImage: boolean = false;

  idWorkshop;
  faEye = faEye;
  faPencil = faPencilAlt;

  domain = new Domain({
    id: null,
    id_workshop: null,
    id_domain: null,
    id_brand: null,
    id_brand_template: null,
    address: '',
    email: '',
    phone: '',
    logo: '',
    site_title: '',
    site_description: '',
    timetable: ''
  })

  constructor(
    public _ss:SharingService,
    public _ap:ApserviceService,
    private route: ActivatedRoute,
    private _config: AppconfigService,
    private router: Router,
    private _ns: NotifierService,
  ) {
    this._ss.titleSection = 'Configuración';
    this._ss.getLocalStorage();
    this._ss.saveButton = false;
    this._ss.newButton = false;
    this.idWorkshop = this.route.snapshot.paramMap.get("id");
    console.log(this.idWorkshop);
    if(this.idWorkshop){  
      console.log("entre aqui");
      this._ss.workshopData = new Setting();      
    }

  }


  ngOnInit() {
    this._ss.saveButton = true;
    this._ss.newButton = false;
    this._ss.titleSection = 'Configuración';
    console.log(this.idWorkshop);
    if(this.idWorkshop){
      if(this._ss.getWorkShopSettings() && this.idWorkshop != this._ss.getWorkShopSettings().id_workshop){
        console.log("Limpiando cookies..");
        this._ss.clearWorkshopSettings();
        this._config.loadConfig(this.idWorkshop);
      }
    }

    console.log(this._ss.workshopData);
    if(!this._ss.workshopData || !this._ss.workshopData.id_workshop){
      console.log('Datos del workshop cargados...');
      this._ss.workshopData = new Setting();  
    }
  }

  changeDomainStatus(id){
    console.log("cambiando el estado...", id);
    this._ap.setDomainStatus(id).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success', 'Activado / Desactivado correctamente!');
        this._ss.workshopData.domains.find(x => x.id == id).status = !this._ss.workshopData.domains.find(x => x.id == id).status;
      }else{
        this._ns.notify('error', 'Error: al activar / desactivar dominio!');
      }
    },(err)=>{
      console.log(err);
      this._ns.notify('error', 'Error: Se ha producido un error: '+ err);
    });
  }

  setDomainId(id){
    console.log(id);
    this.getDomainSettings(id);
    this._ss.isNewDomain = true;
  }

  getDomainSettings(id){
    this._ap.getWorkshopDomainSettings(id).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        if(response.data.length > 0){
          this.domain = response.data[0];
        }else{
          this.domain = new Domain();
          this.domain.id_workshop = this._ss.getWorkShopSettings().id_workshop;
          this.domain.id_domain = id;
        }
        console.log(this.domain);
      }else{
        console.log("Se a producido un error: "+ response.msg);
      }
    },(err)=>{
      console.log("Se a producido un error: "+ err);
    });
  }
 
}
