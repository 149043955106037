import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ApserviceService } from 'src/app/services/apservice.service';

@Component({
  selector: 'app-vo',
  templateUrl: './vo.component.html',
  styleUrls: ['./vo.component.scss']
})
export class VoComponent implements OnInit {

  @ViewChild('iframe') iframe: ElementRef;
  token;
  public secureURL:SafeResourceUrl;
  public isLoading:boolean = false;

  constructor(
    public _ss:SharingService,
    private sinitizer:DomSanitizer,
    private _ap: ApserviceService
  ) {
    this._ss.titleSection = 'VO';
   }

   ngOnInit() {
     //this.onLoad();
     this.isLoading = true;
  }
  
  onLoad()  {  
    this._ap.getVO('digitalizat','admin.360').subscribe((response)=>{
      console.log(response);
      this.isLoading = true;
    });
  }
  
}
