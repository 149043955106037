<div class="form" [ngClass]="{open: isOpen}">
    <div class="title">
        <span class="close" (click)="showCenter()">
            <fa-icon [icon]="faArrowRight"></fa-icon>
        </span>
        <h1>Modificar Centro</h1>
    </div>
    <div class="info-content">
        <div class="info">
            <div>
                <label for="name">
                Nombre centro:
            </label>
                <input name="name" type="text" [(ngModel)]="center.name" />
            </div>

            <label for="description">
            Descripción:
        </label>
            <angular-editor name="description" [(ngModel)]="center.description" [config]="editorConfig"></angular-editor>
            <div class="row">
                <div class="input-address">
                    <label for="address">
                Dirección:
            </label>
                    <input name="address" type="text" [(ngModel)]="center.address.street" (keydown.enter)="$event.preventDefault()" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search placeholder="Busca tu localización" />
                </div>
            </div>
            <div class="row">
                <div class="input-number">
                    <label for="number">
                    Número:
                </label>
                    <input name="number" type="text" [(ngModel)]="center.address.number" (keyup.enter)="sendAutocompleteAddress()" />
                </div>

                <div class="input-city">
                    <label for="city">
                    Ciudad:
                </label>
                    <input name="city" type="text" [(ngModel)]="center.address.council" (keyup.enter)="sendAutocompleteAddress()" />
                </div>

                <div>
                    <label for="province">
                    Provincia:
                </label>
                    <input name="province" type="text" [(ngModel)]="center.address.province" (keyup.enter)="sendAutocompleteAddress()" />
                </div>

            </div>

            <div class="row">
                <div>
                    <label for="province">
                    Teléfono:
                </label>
                    <input name="phone" type="phone" [(ngModel)]="center.phone" />
                </div>
                <div>
                    <label for="province">
                    Email:
                </label>
                    <input name="email" type="email" [(ngModel)]="center.email" />
                </div>
            </div>
            <div class="row">
                <div>
                    <label for="latitude">
                        Latitud:
                    </label>
                    <input type="text" (change)="changeLatitude()" [(ngModel)]="center.address.latitude" />
                </div>
                <div>
                    <label for="longitude">
                        Longitud:
                    </label>
                    <input type="text" (change)="changeLongitude()" [(ngModel)]="center.address.longitude" />
                </div>
            </div>
        </div>

        <div class="map">
            <app-map [center]="center"></app-map>
        </div>
    </div>
    <a class="btn" (click)="saveCenter()">EDITAR Y GUARDAR</a>
</div>