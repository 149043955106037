import {Component, HostListener, OnInit} from '@angular/core';
import { faCog, faCar, faComment, faBars, faWrench, faDollarSign, faPaperclip, faFileAlt, faFileImage, faBook } from '@fortawesome/free-solid-svg-icons';
import { SharingService } from 'src/app/services/sharing.service';
import { icons } from 'src/app/constants';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @HostListener("window:message", ["$event"])
  onPostedMessage(e){
    if(e.data == "toggle-menu"){
      this.openMenu();
    }

  }

  faBars = faBars;
  faCog = faCog;
  faPicture = faFileImage;
  faFile = faFileAlt;
  faWrench = faWrench;
  faChat = faComment;
  faDolar = faDollarSign;
  faPaperClip = faPaperclip;
  faCar = faCar;
  facebook = icons.facebook;
  vo = icons.vo;
  faBook = faBook;
  public id_workshop;

  constructor(
    public _ss:SharingService,
  ) { }

  ngOnInit(): void {
   
  }

  openMenu(){
    console.log("abriendo menú");
    this._ss.isOpen = !this._ss.isOpen;
  
  }


}
