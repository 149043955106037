import { Component, OnInit, OnDestroy, ViewChild} from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { faTimes, faSearch, faChevronDown, faStar } from '@fortawesome/free-solid-svg-icons';
import { Promotion } from  '../../models/promotion';
import { FileUploader } from 'ng2-file-upload';
import * as moment from "moment";
import { NotifierService } from 'angular-notifier';
@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.scss']
})
export class PromotionsComponent implements OnInit, OnDestroy {

  @ViewChild('selectedPicture') selectedPicture: any;

  faClose = faTimes;
  faSearch = faSearch;
  faArrow = faChevronDown;  
  faStar = faStar;
 
  search = {
    status: 3,
    create_date:null,
    expiration_date:null,
    text:null
  }

  isOpened:boolean = false;
  showClearFilter:boolean = false;
  isNewPromotion:boolean = false;

  // FileUpload Service Variables
  uploader:FileUploader;
  isuploadingProgress;
  uploadStatusProgress;
  saveWithImage:boolean = false;
  filename;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;

  saveSubscription;
  newSubscription;

  promotions = [];
  tempPromotions = [];
  promotion: Promotion = {
    id: null,
    title:"",
    description:"",
    image:"",
    banner_image:"",
    start_date:null,
    expiration_date:null,
    status:false,
    featured:false
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Introduce una descripción...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
};

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    private _ns: NotifierService
  ) { 
    this._ss.titleSection = 'Campañas';
    this.uploader = new FileUploader({
      url: this._ss.adminURL + 'upload',
      method: "POST",
      queueLimit: 1,
      allowedMimeType: ['image/png', 'image/jpeg'],
      itemAlias: 'photo',
    });
    this._ss.getLocalStorage();
  }

  ngOnInit(): void {
    this._ss.saveButton = false;
    this._ss.newButton = true;
    this.saveSubscription = this._ss.$save.subscribe(() => {
      this.save();
    });
    this.newSubscription = this._ss.$create.subscribe(() => {
      this.newPromotion();
    });
    this._ap.getMultisitePromotions().subscribe(response =>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.promotions = response.data;
        this.tempPromotions = this.promotions;
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });

    //override the onAfterAddingfile property of the uploader so it doesn't authenticate with //credentials.

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; 
      this.filename = file.file.name;
      if (this.uploader.queue.length >= 1) {
        this.saveWithImage = true;            
      }
    };

    this.uploader.onBeforeUploadItem = (file)=>{
     console.log(file);
    };

    //overide the onCompleteItem property of the uploader so we are 
    //able to deal with the server response.

    this.uploader.onProgressItem = (fileItem: any, progress: any)=>{
      this.isuploadingProgress = true;
      this.uploadStatusProgress = progress+'%';
    }

    this.uploader.onCompleteItem = (item:any, data:any, status:any, headers:any) => {
    data = JSON.parse(data);
    if(data.succes === 'OK'){
    console.log(data);
      let filename = data.file.filename;
      this.promotion.banner_image = filename;
      this.promotion.image = filename;
      this.addPromotion();
      }else{
        console.log("Se ha producido un error");
      }
    }

    this.uploader.onErrorItem = (item: any, response: string, status: number, headers:any) =>{
      console.log(response);
    }

    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any) => {
      console.log('Imagen subida');
      this.selectedPicture.nativeElement.value = '';
    };
    
  }

  changePromotionStatus(id){
    console.log(id);
    console.log(this.promotions);
    console.log("cambiando estado promocion");
    this._ap.changePromotionStatus(id).subscribe(response => {
      console.log(response);
      if(response.result == 'OK'){
        console.log("se ha actualizado correctamente");
        console.log(response.msg);
        this.promotions.find(x => x.id == id).status = !this.promotions.find(x => x.id == id).status;
      }else{
        console.log("no se ha actualizado");
      }
    });
  }

  changePromotionFeatured(id){
    this._ap.changePromotionFeatured(id).subscribe(response => {
      console.log(response);
      if(response.result == 'OK'){
        console.log("se ha actualizado correctamente");
        console.log(response.msg);
        this.promotions.find(x => x.id == id).featured = !this.promotions.find(x => x.id == id).featured;
      }else{
        console.log("no se ha actualizado");
      }
    });
  }

  setPromotion(id){
    this.isNewPromotion = false;
    this.promotion = this.promotions.filter(x => x.id == id)[0];
    this.promotion.start_date = moment(this.promotion.start_date).format('YYYY-MM-DD');
    this.promotion.expiration_date = moment(this.promotion.expiration_date).format('YYYY-MM-DD');
    console.log("Modificando promocion...");
    this.isNewPromotion = true;
  }

  clearSelectedPicture() {
    this.filename = '';
    this.isuploadingProgress = '';
    this.uploadStatusProgress = '';
    this.selectedPicture.nativeElement.value = '';
    this.uploader.clearQueue();
    this.saveWithImage = false;
  }

  delPromotion(id){
    console.log("Eliminando promocion...");
    this._ap.deletePromotion(id).subscribe(response =>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.promotions = this.promotions.filter(x => x.id != id);
        this.filter();
      }else{
        console.log("se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  newPromotion(){
    this.isNewPromotion = true;
    this.promotion = new Promotion({
      id: null,
      title:"",
      description:"",
      image:"",
      banner_image:"",
      start_date:null,
      expiration_date:null,
      status:false,
      featured:false,
    });
    this.clearSelectedPicture();
  }

  addPromotion(){
    this.promotion.start_date = moment(this.promotion.start_date).format("YYYY-MM-D").toString();
    this.promotion.start_date = moment(this.promotion.expiration_date).format("YYYY-MM-D").toString();
    this._ap.addPromotion(this.promotion).subscribe(response=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        if(this.promotion.id != null){
          this._ns.notify("success","Promoción guardada correctamente!");
        }else{
          this._ns.notify("success","Promoción creada correctamente!");
        }
        if(response.id){
          this.promotion.id = response.id;
          this.promotions.push(this.promotion);
          this.tempPromotions.push(this.promotion);
        }
        
        this.promotion = new Promotion({
          id: null,
          title:"",
          description:"",
          image:"",
          banner_image:"",
          start_date:null,
          expiration_date:null,
          status:false,
          featured:false
        });
        this.clearSelectedPicture();
        this.isNewPromotion = false;
      }else{
        this._ns.notify("error","Error: Se ha producido un error al guardar la promoción!");
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  save(){
    console.log(this.saveWithImage);
    if(this.saveWithImage){
      this.uploader.uploadAll();
    }else{
      this.addPromotion();
    }
  }

  showSearch(){
    this.isOpened = !this.isOpened;
  }

  // Resetear Filtrado
  clearFilter(){
    this.search = {
      text: '',
      create_date: '',
      expiration_date: '',
      status:3
    }
    this.tempPromotions = this.promotions;
    this.showClearFilter = false;
  }

  // Filtrado & Buscador
  filter(){   
    this.showClearFilter = true; 
    this.tempPromotions = this.promotions;

    if(this.search.text){
      console.log(this.search);
      this.tempPromotions = this.tempPromotions.filter( promotion => {
        return promotion.title.toLowerCase().includes(this.search.text.toLowerCase());
      });
     }else{
      this.tempPromotions = this.tempPromotions;
    }

    if(this.search.status){
      if(this.search.status == 0 || this.search.status == 1){
        this.tempPromotions = this.tempPromotions.filter(x => x.status == this.search.status);
      }
      
      if(this.search.status == 4){
        this.tempPromotions = this.tempPromotions.filter(x => x.featured == 1);
      }

      if(this.search.status == 3){
        this.tempPromotions = this.tempPromotions;
      }
    }

    if(this.search.create_date){
      this.tempPromotions = this.tempPromotions.filter(x => x.create_date >= this.search.create_date);
    }

    if(this.search.expiration_date){
      this.tempPromotions = this.tempPromotions.filter(x => x.expiration_date <= this.search.expiration_date);
    }

    console.log(this.tempPromotions);
    console.log(this.promotions);
  }
  

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.saveSubscription.unsubscribe();
    this.newSubscription.unsubscribe();
  }

}
