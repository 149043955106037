<div class="container">
    <div class="servicesSelected">
        <div class="row title-brand">
            <label for="brand">
                Elige una marca:
            </label>
            <select [(ngModel)]="id_brand" (change)="changeBrand($event.target.value)">
                <option *ngFor="let brand of brands" [value]="brand.id">{{brand.name}}</option>
            </select>
        </div>
        <div class="warning-services" *ngIf="services.length == 0">
            <p>¡No ha seleccionado ningún servicio!</p>
        </div>
        <div #contenedor class="categories" cdkDropListGroup> 
            <ng-container *ngFor="let service of services;let i=index">
                <div class="categories-item" cdkDropList 
            cdkDropListOrientation="horizontal"
            [cdkDropListData]="{service:service,index:i}" (cdkDropListDropped)="drop($event)" >
                        <div class="service-target"  cdkDrag>
                            <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                            <div class="title">
                                {{service.title}}
                            </div>
                            <div class="ribbon-btn" *ngIf="!service.status">
                                <div class="ribbon" (click)="changeServiceStatus(service)">Desactivado</div>
                            </div>
                            <div class="ribbon-btn" *ngIf="service.status">
                                <div class="ribbon-active" (click)="changeServiceStatus(service)">Activado</div>
                            </div>
                        </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>