import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { faTimes, faSearch, faChevronDown, faStar, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from 'ng2-file-upload';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-banner-modify',
  templateUrl: './banner-modify.component.html',
  styleUrls: ['./banner-modify.component.scss']
})
export class BannerModifyComponent implements OnInit {
  @ViewChild('selectedPicture') selectedPicture: any;

  @Input() banner;

  faClose = faTimes;
  faSearch = faSearch;
  faArrow = faChevronDown;  
  faStar = faStar;
  faArrowRight = faArrowRight;

  isOpen:boolean = false;
  showClearFilter:boolean = false;
 

  // FileUpload Service Variables
  uploader:FileUploader;
  isuploadingProgress;
  uploadStatusProgress;
  saveWithImage:boolean = false;
  filename;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;

  saveSubscription;
  newSubscription;

  editorConfig: AngularEditorConfig = {
    editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '200',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Introduce una descripción...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
        'toggleEditorMode'
      ]
    ]
};

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    private _ns: NotifierService
  ) { 
    this.uploader = new FileUploader({
      url: this._ss.adminURL + 'upload',
      method: "POST",
      queueLimit: 1,
      allowedMimeType: ['image/png', 'image/jpeg'],
      itemAlias: 'photo',
    });
    this._ss.getLocalStorage();
  }

  ngOnInit(): void {

    //override the onAfterAddingfile property of the uploader so it doesn't authenticate with //credentials.

    this.uploader.onAfterAddingFile = (file) => { file.withCredentials = false; 
      this.filename = file.file.name;
      if (this.uploader.queue.length >= 1) {
        this.saveWithImage = true;            
      }
    };

    this.uploader.onBeforeUploadItem = (file)=>{
     console.log(file);
    };

    //overide the onCompleteItem property of the uploader so we are 
    //able to deal with the server response.

    this.uploader.onProgressItem = (fileItem: any, progress: any)=>{
      this.isuploadingProgress = true;
      this.uploadStatusProgress = progress+'%';
    }

    this.uploader.onCompleteItem = (item:any, data:any, status:any, headers:any) => {
    data = JSON.parse(data);
    if(data.succes === 'OK'){
    console.log(data);
      let filename = data.file.filename;
      this.banner.image = filename;
      console.log(this.banner);
      if(this.banner.id != null){
        this.modifyBanner();
      }else{
        this.addBanner();
      }
      }else{
        console.log("Se ha producido un error");
      }
    }

    this.uploader.onErrorItem = (item: any, response: string, status: number, headers:any) =>{
      console.log(response);
    }

    this.uploader.onSuccessItem = (item: any, response: string, status: number, headers: any) => {
      console.log('Imagen subida');
      this.selectedPicture.nativeElement.value = '';
    };
    
  }




  clearSelectedPicture() {
    this.filename = '';
    this.isuploadingProgress = '';
    this.uploadStatusProgress = '';
    this.selectedPicture.nativeElement.value = '';
    this.uploader.clearQueue();
    this.saveWithImage = false;
  }


  newSlider(){
    this.banner = {
      id: null,
      id_workshop: this._ss.getWorkShopSettings().id_workshop,
      title:'',
      description:'',
      image: '',
      status:false,
    };
    this._ss.isNewBanner = true;
  }

  addBanner(){
    console.log("añadiendo banner...");
    this._ap.addBanner(this.banner).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.banner.id = response.id;
        console.log(response);
        this._ss.banners.push(this.banner);
        this._ns.notify('success','Guardado correctamente!');
      }else{
        console.log(response.msg);
        this._ns.notify('error','Error: Se ha producido un error en el guardado.');
      }
      this._ss.isNewBanner = false;
    },(err)=>{
      console.log("Se ha producido un error", err);
    });
    this.clearSelectedPicture();
  }

  modifyBanner(){
    console.log("modificando banner...");
    this._ap.modifyBanner(this.banner).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this._ns.notify('success','Guardado correctamente!');
      }else{
        console.log(response.msg);
        this._ns.notify('error','Error: Se ha producido un error en el guardado.');
      }
      this._ss.isNewBanner = false;
    },(err)=>{
      console.log("Se ha producido un error", err);
    });
    this.clearSelectedPicture();
  }

  save(){
    console.log(this.saveWithImage);
    if(this.saveWithImage){
      this.uploader.uploadAll();
    }else{
      if(this.banner.id != null){
        this.modifyBanner();
      }else{
        this.addBanner();
      }
    }
  }

  showBanner(){
    this._ss.isNewSlider = false;
    this._ss.isNewBanner= !this._ss.isNewBanner;
  }
}
