import { Injectable, EventEmitter } from '@angular/core';
import { siteURL } from '../config';
import { Setting } from '../models/settings';

@Injectable({
  providedIn: 'root'
})
export class SharingService {

  isOpen = false;
  saveButton = false;
  newButton = false;
  storageName = "driver360Source";
  titleSection;
  adminURL = siteURL.host_backend + siteURL.endpoint_admin;
  imgURL = siteURL.image_url;

  workshopData:Setting;
  
  sliders = [];
  banners = [];
  isNewSlider:boolean = false;
  isNewBanner:boolean = false;
  isNewDomain:boolean = false;

  $save = new EventEmitter();
  $create = new EventEmitter();

  constructor() { }

  saveEvent(){
    console.log("Guardando....");
    this.$save.emit();
  }

  createEvent(){
    console.log("Creando....");
    this.$create.emit();
  }

  setLocalStorage(dataSource){
    localStorage.setItem(this.storageName, dataSource);
  }

  getLocalStorage(){
    let data = localStorage.getItem(this.storageName);
    this.workshopData = JSON.parse(data);
  }

  getWorkShopSettings():any {
    return this.workshopData;
  }

  clearWorkshopSettings(){
    localStorage.removeItem(this.storageName);
    this.workshopData = null;
  }

}
