import { Component, OnInit, OnDestroy } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import { ApserviceService } from 'src/app/services/apservice.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import { createOfflineCompileUrlResolver } from '@angular/compiler';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit, OnDestroy {

  services = [];
  brands;
  id_brand;
  saveSubscription;

  constructor(
    public _ss: SharingService,
    private _ap: ApserviceService,
    private _ns: NotifierService
  ) { 
    this._ss.getLocalStorage();
  }

  ngOnInit(): void {
    this._ss.saveButton = true;
    this._ss.newButton = false;
    this.saveSubscription = this._ss.$save.subscribe(() =>{
      this.saveServices();
    });
    this.getBrands();
    this._ss.titleSection = 'Servicios';
    console.log(this.services);
  }


  drop(event: CdkDragDrop<any>) {
    this.services[event.previousContainer.data.index]=event.container.data.service
    this.services[event.container.data.index]=event.previousContainer.data.service
  }

  getBrandServices(id){
    console.log("la marca es: "+ id);
    this._ap.getBrandServices(id).subscribe(response =>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.services = response.data;
        console.log(this.services);
        this.services.forEach(element => {
          console.log(element);
          element.status = false;
        });
        this.getServices();
      }else{
        console.log(response.msg);
      }
    });
  
  }

  getServices(){
    this._ap.getServices().subscribe(response =>{
      if(response.result == 'OK'){
        console.log(response.msg);
        let worshopservices = response.data;
        if(worshopservices.length > 0){
          worshopservices.forEach(element => {
              this.services.find(x => x.id_service == element.id_service).status = element.status;
              this.services.find(x => x.id_service == element.id_service).order = element.order;
              let elt = this.services.findIndex(x => x.id_service == element.id_service);
              if(elt == -1){
                this.services = this.services.filter(x=> x.id_service != element.id_service);
              }
          });
          this.sortServices();
        }
        console.log(this.services);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  sortServices(){
    this.services.sort(function (a, b) {
      return a.order -  b.order;
    });
  }

  changeServiceStatus(service){
    console.log(this.services);
    console.log(service);
    this.services.find(x => x.id_service == service.id_service).status = !service.status;
  }

  getBrands(){
    this._ap.getBrands().subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.brands = response.data;
        this.id_brand = response.data[0].id;
        let id = response.data[0].id;
        console.log("la marca es....");
        this.getBrandServices(id);
      }else{
        console.log(response.msg);
        console.log("Se ha producido un error");
      }
    });
  }

  changeBrand(id){
    this.services = [];
    this.getBrandServices(id);
  }

  saveServices(){
    console.log(this.services);
    this._ap.setWorkShopServices(this.services).subscribe(response =>{
      console.log(response);
      if(response.result == 'OK'){
        console.log(response.msg);
        this._ns.notify("success","Guardado correctamente!");
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
        this._ns.notify("success","Error: Se ha producido un error en el guardado!");
      }
    });
  }

  ngOnDestroy(){
    // Me cargo las subscripciones a los eventos
    this.saveSubscription.unsubscribe();
  }

}
