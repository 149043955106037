<div class="container">
   
    <div #contenedor class="categories" cdkDropListGroup>
        <div class="page-active">
            <label>Activar Módulo</label>
            <label class="switch">
                <input type="checkbox" (change)="setPageActive()" [checked]="page.active">
                <span class="slider round"></span>
            </label>
        </div> 
        <ng-container *ngFor="let partner of partners;let i=index">
            <div class="categories-item" cdkDropList 
        cdkDropListOrientation="horizontal"
        [cdkDropListData]="{partner:partner,index:i}" (cdkDropListDropped)="drop($event)" >
                    <div class="partner-target"  cdkDrag>
                        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>
                        <div class="title">
                            {{partner.title}}
                        </div>
                        <div class="ribbon-btn" *ngIf="!partner.status">
                            <div class="ribbon" (click)="changePartnerStatus(partner)">Desactivado</div>
                        </div>
                        <div class="ribbon-btn" *ngIf="partner.status">
                            <div class="ribbon-active" (click)="changePartnerStatus(partner)">Activado</div>
                        </div>
                    </div>
            </div>
        </ng-container>
    </div>
</div>