import { Component, OnInit, ViewChild, Input, OnChanges } from '@angular/core';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FileUploader } from 'ng2-file-upload';
import { SharingService } from 'src/app/services/sharing.service';
import { AppconfigService } from 'src/app/services/appconfig.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NotifierService } from 'angular-notifier';
import { Domain } from 'src/app/models/domain';
import { ApserviceService } from 'src/app/services/apservice.service';
import { ThrowStmt } from '@angular/compiler';
import { OwlOptions } from 'ngx-owl-carousel-o';


@Component({
  selector: 'app-configuration-site',
  templateUrl: './configuration-site.component.html',
  styleUrls: ['./configuration-site.component.scss']
})
export class ConfigurationSiteComponent implements OnInit, OnChanges {

  @ViewChild('selectedPicture') selectedPicture: any;

  @Input() domain: Domain;
  templates = [];

  faArrowRight = faArrowRight;

  imagePath;
  message;
  imgURL;

  isuploadingProgress;
  uploadStatusProgress;
  hasBaseDropZoneOver:boolean;
  hasAnotherDropZoneOver:boolean;

  customOptions: OwlOptions = {
    loop: true,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 3
      },
      940: {
        items: 4
      }
    },
    nav: true
  }


  constructor(
    public _ss:SharingService,
    public _ap: ApserviceService,
    private _ns: NotifierService,
  ) {
    this._ss.titleSection = 'Configuración';
    console.log(this.domain);
  }


  ngOnInit() {
    this._ss.saveButton = false;
    this._ss.newButton = false;
    this._ss.titleSection = 'Configuración';
  }

  addSettings(){
    let settings = this._ss.getWorkShopSettings();
    this._ap.addMultisiteSettings(settings).subscribe(response => {
      if(response.result == 'OK'){
        console.log(response.msg);
        this._ns.notify("success", "Guardado correctamente!");
      }else{
        this._ns.notify("error", "Error: Se ha producido un error en el guardado!");
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  updateSettings(){
    this._ap.setMultisiteSettings(this._ss.workshopData).subscribe(response => {
      if(response.result == 'OK'){
        console.log(response.msg);
        this._ns.notify("success", "Guardado correctamente!");
      }else{
        this._ns.notify("error", "Error: Se ha producido un error en el guardado!");
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  showDomain(){
    this._ss.isNewDomain = false;
  }

  saveDomain(){
    if(this.domain.id != null){
      this.modifyDomainSettings();
    }else{
      this.addDomainSettings();
    }
    this._ss.isNewDomain = false;
  }

  modifyDomainSettings(){
    this._ap.setMultisiteSettings(this.domain).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success',' Guardado correctamente!');
        this.getDomains();
      }else{
        console.log(response.msg);
        this._ns.notify('error','Error: al guardar!');
      }
    },(err)=>{
      this._ns.notify('error','Error: '+err)
    });
  }

  addDomainSettings(){
    this._ap.addMultisiteSettings(this.domain).subscribe((response)=>{
      if(response.result == 'OK'){
        this._ns.notify('success',' Guardado correctamente!');
      }else{
        console.log(response.msg);
        this._ns.notify('error','Error: al guardar!');
      }
    },(err)=>{
      this._ns.notify('error','Error: '+err)
    });
  }

  getDomains(){
    this._ap.getWorkshopDomains().subscribe((response)=>{
      if(response.result == 'OK'){
        this._ss.workshopData.domains = response.data;
      }
    },(err)=>{

    });
  }

  getTemplates(){
    this._ap.getTemplates(this.domain.id_brand).subscribe((response)=>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.templates = response.data;
      }else{
        console.log(response.msg);
      }
    },(err)=>{
      console.log("Se ha producido un error", err);
    });
  }

  setThemeActive(id){
    this.domain.id_brand_template = id;
    console.log(this.domain);
  }

  ngOnChanges(){
    this.getTemplates();
  }

}
