import { Component, OnInit, ComponentFactoryResolver, Input } from '@angular/core';
import { SharingService } from 'src/app/services/sharing.service';
import {ApserviceService} from "../../services/apservice.service";
import {Page} from "../../models/page";
import {PAGE_TYPES_IDS} from "../../constants";
import { NotifierService } from 'angular-notifier';
import { faBoxTissue } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {

  activePageTab;
  pages = [];

  page: Page;

  constructor(
    public _ss: SharingService,
    public _ap: ApserviceService,
    public _ns: NotifierService
  ) { 
    this._ss.getLocalStorage();
    this.page = new Page ({
      id: null,
      id_page_type:0,
      id_workshop: this._ss.getWorkShopSettings().id,
      active: false,
      name:"",
      banner_title:"",
      banner_image:"",
      content_title:"",
      content_description:"",
      content_image:""
    });
   }

  ngOnInit(): void {
    this._ss.saveButton = true;
    this._ss.newButton = false;
    this._ss.titleSection = 'Páginas';
    this.addWorkshopPages();
  }


  getWorkShopPages(){
  this._ap.getPagesByWorkshopId().subscribe((response) =>{
    if(response.result == 'OK'){
      console.log("getPagesByWorkshopId");
      console.log(response.msg);
      this.pages = response.data;

      this.pages = this.pages.filter(x => x.status == 1);
      this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.PARTNER_PAGE_TYPE_ID);
      this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.RESERVATION_PAGE_TYPE_ID);
      this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.TERMS_PAGE_TYPE_ID);
      this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.POLICIES_PAGE_TYPE_ID);
      this.pages = this.pages.filter(x => x.id_page_type != PAGE_TYPES_IDS.LEGAL_ADVICE_PAGE_TYPE_ID);
      this.pages.forEach(page => {
        page.checked = false;
        /*
        switch (page.id_page_type) {
          case PAGE_TYPES_IDS.SERVICE_PAGE_TYPE_ID:
            page.class = "border-green";
          break;
          case PAGE_TYPES_IDS.CONTACT_PAGE_TYPE_ID: page.class = "border-blue";break;
          case PAGE_TYPES_IDS.PROMOTION_PAGE_TYPE_ID: page.class = "border-red";break;
          case PAGE_TYPES_IDS.WHO_WE_ARE_PAGE_TYPE_ID: page.class = "border-yellow";break;
          case PAGE_TYPES_IDS.VEHICLE_PAGE_TYPE_ID: page.class = "border-cyan";break;
        }
        */
      });
      this.activePageTab = this.pages[0].id_page_type;
      this.pages[0].checked = true;
    }else{
      console.log("Se ha producido un error!");
      console.log(response.msg);
    }
  });
}

  addWorkshopPages(){
    this._ap.addPagestoWorkshop().subscribe((response) =>{
      if(response.result == 'OK'){
        console.log(response.msg);
        this.getWorkShopPages();
      }else{
        console.log("Se ha producido un error");
        console.log(response.msg);
      }
    });
  }

  changeActivePageTab(pageTypeId) {
    this.activePageTab = pageTypeId;
    this.pages.find(x => x.checked == true).checked = false;
    this.pages.find(x => x.id_page_type == pageTypeId).checked = true;
    console.log(this.pages);
    console.log(pageTypeId);
    this.page = this.pages.find(x => x.id_page_type == pageTypeId);
    this.page.active = this.pages.find(x => x.id_page_type == pageTypeId).active;
    (this.page.active) ? this.page.active = true : this.page.active = false;
    console.log(this.page);
    this._ss.saveButton = true;
    this._ss.newButton = false;
    if(this.activePageTab == PAGE_TYPES_IDS.HOME_PAGE_TYPE_ID){
      this._ss.saveButton = false;
      this._ss.newButton = false;
    }
  }

  setPageActive(){
    console.log(this.page);
    this.page.active = !this.page.active;
    console.log(this.page);
    this._ap.setActivePage(this.page).subscribe((response)=>{
      if(response.result == 'OK'){
        if(this.page.active){
          this._ns.notify("success","Página activada correctamente");
        }else{
          this._ns.notify("success","Página desactivada correctamente");
        }
      }else{
        this._ns.notify("error","Error: al activar/desactivar la pagina");
      }
    },(err)=>{
      console.log("Se ha producido un error", err);
    });
}

}
