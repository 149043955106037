import { Component, OnInit, ViewChild, ElementRef, NgZone, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { MapsAPILoader, MouseEvent } from '@agm/core';
import { typeWithParameters } from '@angular/compiler/src/render3/util';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

let markerId = 0;

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnChanges {

  @Input() center;

  place = {
    address: [],
    latitude: null,
    longitude: null,
  };
  title: string;
  draggable = false;
  iconUrl: string;
  visible = true;
  openInfoWindow = true;
  opacity = 1;
  zIndex = 1;
  clickable = true;
  zoom: number = 8;

  private geoCoder;

  showAlert = true;
  faClose = faTimes;

  constructor(
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader
  ) { }

  ngOnChanges(){
    //load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      if(!this.center.address){
        this.setCurrentLocation();
      }
      this.geoCoder = new google.maps.Geocoder;
    });
    this.showAlert = true;
  }

  // Get Current Location Coordinates
  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center.address.latitude = position.coords.latitude;
        this.center.address.longitude = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.center.address.latitude, this.center.address.longitude);
      });
    }
  }

  markerDragEnd($event: MouseEvent) {
    let latitude = $event.coords.lat;
    let longitude = $event.coords.lng;
    this.getAddress(latitude, longitude);
    this.center.address.latitude = latitude;
    this.center.address.longitude = longitude;
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.place.address = results[0].address_components;
          this.getgoogleAddress(results[0]);
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }
    });
  }

  showInfo(infoWindow){

  }

  hideAlert(){
    this.showAlert = false;
  }

  getgoogleAddress(address: any){

    console.log(address.address_components.length);
    switch(address.address_components.length){
      case 1:

      case 2:

      case 3:

      case 4:
        this.center.address.number = '';
        this.center.address.street = address.address_components[0].long_name;
        this.center.address.council = address.address_components[1].long_name;
        this.center.address.province = address.address_components[2].long_name;
        this.center.address.postalCode = address.address_components[3].long_name;
      break;

      case 5:
        if(address.address_components[0].types[0] == 'route'){
          console.log("entre aqui");
          this.center.address.number = '';
          this.center.address.street = address.address_components[0].long_name;
          this.center.address.council = address.address_components[1].long_name;
          this.center.address.province = address.address_components[2].long_name;
          this.center.address.community = address.address_components[3].long_name;
          this.center.address.country = address.address_components[4].long_name;
        }else{
          this.center.address.number = '';
          this.center.address.postalCode = address.address_components[0].long_name;
          this.center.address.council = address.address_components[1].long_name;
          this.center.address.province = address.address_components[2].long_name;
        }
      break;

      case 6:
        if(address.address_components[0].types[0] == 'street_number'){
          this.center.address.number = address.address_components[0].long_name;
          this.center.address.street = address.address_components[1].long_name;
          this.center.address.council = address.address_components[2].long_name;
          this.center.address.province = address.address_components[2].long_name;
          this.center.address.community = address.address_components[3].long_name;
          this.center.address.country = address.address_components[4].long_name;
          this.center.address.postalCode = address.address_components[5].long_name;
        }else{
          this.center.address.number = '';
          this.center.address.street = address.address_components[0].long_name;
          this.center.address.council = address.address_components[1].long_name;
          this.center.address.province = address.address_components[2].long_name;
        }
      break;
      default:
        this.center.address.number = address.address_components[0].long_name;
        this.center.address.street = address.address_components[1].long_name;
        this.center.address.council = address.address_components[2].long_name;
        this.center.address.province = address.address_components[3].long_name;
    }
    console.log(address);
    console.log(this.center);
  }

}