export class Setting {
    // Campos obligatorios implementados por la interfaz
    id: number;
    id_workshop: number;
    id_brand: number;
    domains: any;
    address: string;
    email: string;
    phone: string;
    logo: string;
    site_title: string;
    site_description: string;
    timetable: string;
  
  
      constructor (s?: Setting) {
        // this.sales = a && a.sales || [new Sale()];
        this.id = s && s.id || null;
        this.id_workshop = s && s.id_workshop || null;
        this.id_brand = s && s.id_brand || null;
        this.domains = s && s.domains || new Array();
        this.address = s && s.address || '';
        this.email = s && s.email || '';
        this.phone = s && s.phone || '';
        this.logo = s && s.logo || '';
        this.site_title = s && s.site_title || '';
        this.site_description = s && s.site_description || '';
        this.timetable = s && s.timetable || '';
      }
  }
  